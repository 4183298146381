import React from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";

import {
  SecurityTitle,
  SecuritySubTitle,
  SpecialHeroContent,
  SpecialHeroSection,
} from "./styles";

const SecurityHero = () => {
  const { t } = useTranslation();

  return (
    <SpecialHeroSection>
      <SpecialHeroContent>
        <SecuritySubTitle>{t("SECURITY")}</SecuritySubTitle>
        <SecurityTitle>{t("Security and Compliance")}</SecurityTitle>
        <p>
          {t(
            "We're proud to run an information security and privacy program that exceeds the industry standard when it comes to protecting your organization."
          )}
        </p>
      </SpecialHeroContent>
    </SpecialHeroSection>
  );
};

export default SecurityHero;
