import React from "react";

import { graphql } from "gatsby";

import { useTranslation } from "gatsby-plugin-react-i18next";

import SecurityValues from "assets/images/security-value.svg";
import CallToAction from "components/CallToAction";
import PageContainer from "components/PageContainer";
import {
  SecurityDataPrivacyWrapper,
  SecurityValuesImage,
  SeurityValuesImageWrapper,
  ValuesTextWrapper,
  DataPrivacySubtitle,
  SecurityMiniTitle,
} from "components/Security";
import SecurityFeatures from "components/Security/SecurityFeatures";
import SecurityHero from "components/Security/SecurityHero";
import SEO from "components/SEO";

const SecurityPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <SecurityHero />
      <SecurityDataPrivacyWrapper>
        <SeurityValuesImageWrapper>
          <SecurityValuesImage src={SecurityValues} alt="security" />
        </SeurityValuesImageWrapper>
        <ValuesTextWrapper>
          <DataPrivacySubtitle>{t("DATA PRIVACY")}</DataPrivacySubtitle>
          <SecurityMiniTitle>
            {t("We value our clients data")}
          </SecurityMiniTitle>
          <p>{t("dataSecurity")}</p>
        </ValuesTextWrapper>
      </SecurityDataPrivacyWrapper>
      <SecurityFeatures />
      <CallToAction />
    </PageContainer>
  );
};

export default SecurityPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO title="Security" pathname={pathname} />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["security", "common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
