import React from "react";

import { useTranslation } from "gatsby-plugin-react-i18next";
import styled from "styled-components";

import {
  SecurityFeaturesHeaderWrapper,
  SecuritySubTitle,
  SecurityTitle,
  SecurityFeaturesWrapper,
  SecurityFeatureItem,
} from "./styles";

const FEATURES = [
  {
    title: "Multi-factor authentication",
    description:
      "Require a second verification step in addition to entering a password.",
  },
  {
    title: "GDPR",
    description:
      "Strict adherence to GDPR standards in all regions where we operate",
  },
  {
    title: "Infrastructure",
    description: "IT infrastructure in secure locations with restricted access",
  },
  {
    title: "Encryption",
    description: "High level encryption of sensitive information",
  },
  {
    title: "Audits",
    description: "Quarterly, semi-annual, and annual internal audits",
  },
  {
    title: "ISO 27001",
    description: "Adherence to ISO 27001 standards",
  },
];

const Title = styled(SecurityTitle)`
  font-size: 40;
  color: white;
`;

const SecurityFeatures = () => {
  const { t } = useTranslation();

  return (
    <SecurityFeaturesHeaderWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <SecuritySubTitle style={{ marginBottom: 4 }}>
          {t("FEATURES")}
        </SecuritySubTitle>
        <Title style={{ marginTop: 0, marginBottom: 16 }} as="h2">
          {t("Data Security Features")}
        </Title>
        <p>
          {t("We built our application with your security concerns in mind.")}
        </p>
      </div>
      <SecurityFeaturesWrapper>
        {FEATURES.map(({ title, description }) => (
          <SecurityFeatureItem>
            <h3 style={{ color: "white", fontSize: "18px", fontWeight: "600" }}>
              {t(title)}
            </h3>
            <p>{t(description)}</p>
          </SecurityFeatureItem>
        ))}
      </SecurityFeaturesWrapper>
    </SecurityFeaturesHeaderWrapper>
  );
};

export default SecurityFeatures;
