import styled from "styled-components";

import BGDotTexture from "assets/images/bg-dot-texture.svg";

export const SpecialHeroSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 7em 1em;
  min-height: 25vh;

  background-image: url(${BGDotTexture}),
    linear-gradient(72deg, #1f2058 65%, #00033f);
  background-position: 0px 0px, 0px 0px;
  background-size: auto, auto;

  @media (min-width: 750px) {
    padding: 8em 0em;
  }
`;

export const SpecialHeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  max-width: 40em;

  p {
    font-size: 16px;
  }
`;

export const SecuritySubTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 10%;
  color: #12ce9f;
`;

export const SecurityTitle = styled.h1`
  font-size: 44px;
  font-weight: bold;
  line-height: 150%;

  margin-top: 8px;

  @media (min-width: 750px) {
    font-size: 48px;
  }
`;

export const SecurityDataPrivacyWrapper = styled.section`
  display: flex;
  flex-direction: column;

  padding: 3.5em 1.5em;

  background-image: linear-gradient(180deg, #e5e5e5, #fff);
  color: #00033f;

  @media (min-width: 950px) {
    gap: 8em;
    flex-direction: row-reverse;
    align-items: center;

    padding: 7.5em 13vw;
    padding-bottom: 9em;
  }
`;

export const SeurityValuesImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 1em;
  margin-bottom: 4em;

  @media (min-width: 950px) {
    flex-shrink: 0;
    margin-bottom: 0;
    height: 280px;
    padding: 0;
  }
`;

export const SecurityValuesImage = styled.img`
  width: 100%;
  max-width: 400px;

  @media (min-width: 950px) {
    width: 25vw;
  }
`;

export const ValuesTextWrapper = styled.div`
  p {
    color: #00033f;
    line-height: 160%;
    padding-bottom: 16px;
    font-size: 16px;
  }
`;

export const DataPrivacySubtitle = styled(SecuritySubTitle)`
  margin-bottom: 0;

  @media (min-width: 950px) {
    display: none;
  }
`;

export const SecurityMiniTitle = styled.h2`
  font-size: 28px;
  font-weight: 600;
  line-height: 150%;

  margin-top: 0px;
  margin-bottom: 16px;

  @media (min-width: 750px) {
    font-size: 32px;
  }
`;

export const SecurityFeaturesHeaderWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: linear-gradient(90.64deg, #1f2058 -7.77%, #00033f 106.22%);
  padding: 2em 1.25em;

  p {
    font-size: 16px;
    margin-top: 0;
  }

  @media (min-width: 750px) {
    padding: 3.5em 13vw;
    padding-bottom: 8em;
    background: #00033f;
  }
`;

export const SecurityFeaturesWrapper = styled.div`
  display: grid;
  gap: 1.5em;
  grid-auto-rows: 1fr;

  margin-top: 2.5em;
  width: 100%;

  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export const SecurityFeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  border-radius: 8px;
  background-color: #1d2055;

  padding: 0.5em 1em;
  padding-bottom: 2em;

  p {
    font-size: 16px;
  }
`;
