import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const SectionWrapper = styled.section`
  display: grid;
  padding: 4em ${(props) => (props.large ? "calc(15vw / 2)" : "calc(13vw)")};
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: start;
  gap: 2em;
  background-image: ${(props) =>
    props.dark
      ? "linear-gradient(180deg, #00033f, #1f2058)"
      : "linear-gradient(180deg, #e5e5e5, #fff)"};
  color: ${(props) => (props.dark ? "#00033f" : "#fff")};

  @media (min-width: 850px) {
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr;
    gap: 5em;
    justify-content: center;
    align-items: center;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Poppins, sans-serif;
    color: ${(props) => (props.dark ? "#fff" : "#00033f")};
  }

  p {
    color: ${(props) => (props.dark ? "#fff" : "#00033f")};
  }

  .big {
    font-size: 68px;
    line-height: 70px;
    font-weight: 600;
    text-decoration: none;
    text-transform: none;
  }

  @media (min-width: 750px) {
    min-width: 300px;
  }
`;

const SectionRight = styled.div`
  @media (min-width: 750px) {
    height: 100%;
  }
`;

const SectionLeft = styled.div`
  @media (min-width: 750px) {
    height: 100%;
  }
`;

const Section = ({ children, dark, large, style }) => (
  <SectionWrapper dark={dark} large={large} style={{ ...style }}>
    {children}
  </SectionWrapper>
);

Section.defaultProps = {
  dark: false,
  large: false,
  style: {},
  children: null,
};

Section.propTypes = {
  dark: PropTypes.bool,
  large: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Section.Right = SectionRight;
Section.Left = SectionLeft;

export default Section;
