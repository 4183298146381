import React from "react";

import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import PaperAirplane from "assets/images/paper-airplane.png";

import Divider from "../Divider";
import Section from "../QantevSection";

const CallToAction = () => {
  const { t } = useTranslation(["common"]);

  return (
    <Section large>
      <Section.Left>
        <Divider
          icon={
            <div
              style={{
                textTransform: "uppercase",
                color: "#00033f",
                fontSize: "15px",
                lineHeight: "24px",
                fontWeight: 700,
                letterSpacing: "1px",
              }}
            >
              {t("Interested?")}
            </div>
          }
          color="#00033f"
        />
        <Link to="/demo">
          <b className="big" style={{ color: "#00033f" }}>
            &gt;&gt;&nbsp;{t("Request Demo")}
          </b>
        </Link>
      </Section.Left>
      <Section.Right>
        <img src={PaperAirplane} alt="" />
      </Section.Right>
    </Section>
  );
};

export default CallToAction;
