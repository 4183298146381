import React from "react";

import PropTypes from "prop-types";
import styled from "styled-components";

const Icon = styled.div`
  img,
  svg {
    width: auto;
    height: 25px;
    margin-top: 0px;
  }

  h1 {
    width: 20rem;
    margin-bottom: 20px;
    margin-top: 0;
  }

  h4 {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const Title = styled.h2`
  color: #00033f;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;

const DividerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  > *:not(:last-child) {
    margin-right: 16px;
  }

  > div:last-child {
    border: 1px solid ${(props) => props.color || "#fff"};
    width: 100%;
  }
`;

const Divider = ({ icon, text, color, style }) => (
  <DividerWrapper color={color} style={{ ...style }}>
    {icon && <Icon>{icon}</Icon>}
    {text && <Title>{text}</Title>}
    <div />
  </DividerWrapper>
);
Divider.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Divider.defaultProps = {
  icon: null,
  text: "",
  color: "#fff",
  style: {},
};

export default Divider;
